export default [
    {
        path: '/administrator',
        name: 'list-administrator',
        component: () => import('@/views/user/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.administrator',
            resource: 'administrator',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAdministrator',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/administrator/create',
        name: 'create-administrator',
        component: () => import('@/views/user/Create.vue'),
        meta: {
            pageTitle: 'breadcrumb.administrator',
            navActiveLink: 'list-administrator',
            resource: 'administrator',
            action: 'create',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAdministrator',
                    to: { name: 'list-administrator' }
                },
                {
                    text: 'breadcrumb.createAdministrator',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/administrator/:id/update',
        name: 'update-administrator',
        component: () => import('@/views/user/Update.vue'),
        meta: {
            pageTitle: 'breadcrumb.administrator',
            navActiveLink: 'list-administrator',
            resource: 'administrator',
            action: 'update',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAdministrator',
                    to: { name: 'list-administrator' }
                },
                {
                    text: 'breadcrumb.updateAdministrator',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/administrator/:id',
        name: 'view-administrator',
        component: () => import('@/views/user/View.vue'),
        meta: {
            pageTitle: 'breadcrumb.administrator',
            navActiveLink: 'list-administrator',
            resource: 'administrator',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAdministrator',
                    to: { name: 'list-administrator' }
                },
                {
                    text: 'breadcrumb.viewAdministrator',
                    active: true,
                },
            ],
        },
    },
]
