export default [
    {
        path: '/senior',
        name: 'list-senior',
        component: () => import('@/views/senior/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.senior',
            resource: 'senior',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listSenior',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/senior/create',
        name: 'create-senior',
        component: () => import('@/views/senior/Create.vue'),
        meta: {
            pageTitle: 'breadcrumb.senior',
            navActiveLink: 'list-senior',
            resource: 'senior',
            action: 'create',
            breadcrumb: [
                {
                    text: 'breadcrumb.listSenior',
                    to: { name: 'list-senior' }
                },
                {
                    text: 'breadcrumb.createSenior',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/senior/:id/update',
        name: 'update-senior',
        component: () => import('@/views/senior/Update.vue'),
        meta: {
            pageTitle: 'breadcrumb.senior',
            navActiveLink: 'list-senior',
            resource: 'senior',
            action: 'update',
            breadcrumb: [
                {
                    text: 'breadcrumb.listSenior',
                    to: { name: 'list-senior' }
                },
                {
                    text: 'breadcrumb.updateSenior',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/senior/:id',
        name: 'view-senior',
        component: () => import('@/views/senior/View.vue'),
        meta: {
            pageTitle: 'breadcrumb.senior',
            navActiveLink: 'list-senior',
            resource: 'senior',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listSenior',
                    to: { name: 'list-senior' }
                },
                {
                    text: 'breadcrumb.viewSenior',
                    active: true,
                },
            ],
        },
    },
]
