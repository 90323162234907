import ProfileRepository from "./Api/ProfileRepository";
import CurrencyRepository from "./Api/CurrencyRepository";
import UserRepository from "./Api/UserRepository";
import RoleRepository from "./Api/RoleRepository";
import PermissionRepository from "./Api/PermissionRepository";
import CompanyRepository from "./Api/CompanyRepository";
import SeniorRepository from "./Api/SeniorRepository";
import MasterRepository from "./Api/MasterRepository";
import AgentRepository from "./Api/AgentRepository";
import PlayerRepository from "./Api/PlayerRepository";
import AccountRepository from "./Api/AccountRepository";
import TransactionRepository from "./Api/TransactionRepository";
import BetSettingRepository from "./Api/BetSettingRepository";
import BetRepository from "./Api/BetRepository";
import JackpotSettingRepository from "./Api/JackpotSettingRepository";
import ResultRepository from "./Api/ResultRepository";
import BalanceReportRepository from "./Api/BalanceReportRepository";
import ResultReportRepository from "./Api/ResultReportRepository";
import DashboardRepository from "./Api/DashboardRepository";
import BetHistoryRepository from "./Api/BetHistoryRepository";
import DomainRepository from "./Api/DomainRepository";

const repositories = {
    'dashboard': DashboardRepository,
    'profile': ProfileRepository,
    'currency': CurrencyRepository,
    'user': UserRepository,
    'role': RoleRepository,
    'permission': PermissionRepository,
    'company': CompanyRepository,
    'senior': SeniorRepository,
    'master': MasterRepository,
    'agent': AgentRepository,
    'player': PlayerRepository,
    'account': AccountRepository,
    'transaction': TransactionRepository,
    'bet': BetRepository,
    'betSetting': BetSettingRepository,
    'jackpotSetting': JackpotSettingRepository,
    'result': ResultRepository,
    'balanceReport': BalanceReportRepository,
    'resultReport': ResultReportRepository,
    'betHistory': BetHistoryRepository,
    'domain': DomainRepository,
};

export default {
    get: name => repositories[name]
};
