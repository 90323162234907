import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/user`;

export default {
  index(payload) {
    payload.searchFields = [
      ...payload.searchFields,
    ];
    payload = {
      ...payload,
    };
    const params = prepareSearchParams(payload);
    return Client.post(resource, {
      ...params
    }, {
      headers: {
        action: "user-list",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "user-store",
      },
    });
  },
  show(id, payload = {}) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "user-show",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "user-update",
      },
    });
  },
};
