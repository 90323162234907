export default [
    {
        path: '/master',
        name: 'list-master',
        component: () => import('@/views/master/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.master',
            resource: 'master',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listMaster',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/master/create',
        name: 'create-master',
        component: () => import('@/views/master/Create.vue'),
        meta: {
            pageTitle: 'breadcrumb.master',
            navActiveLink: 'list-master',
            resource: 'master',
            action: 'create',
            breadcrumb: [
                {
                    text: 'breadcrumb.listMaster',
                    to: { name: 'list-master' }
                },
                {
                    text: 'breadcrumb.createMaster',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/master/:id/update',
        name: 'update-master',
        component: () => import('@/views/master/Update.vue'),
        meta: {
            pageTitle: 'breadcrumb.master',
            navActiveLink: 'list-master',
            resource: 'master',
            action: 'update',
            breadcrumb: [
                {
                    text: 'breadcrumb.listMaster',
                    to: { name: 'list-master' }
                },
                {
                    text: 'breadcrumb.updateMaster',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/master/:id',
        name: 'view-master',
        component: () => import('@/views/master/View.vue'),
        meta: {
            pageTitle: 'breadcrumb.master',
            navActiveLink: 'list-master',
            resource: 'master',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listMaster',
                    to: { name: 'list-master' }
                },
                {
                    text: 'breadcrumb.viewMaster',
                    active: true,
                },
            ],
        },
    },
]
