import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/report`;

export default {
  index(payload) {
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    }
    return Client.post(resource, params, {
      headers: {
        action: "balance-report",
      },
    });
  },
  total(payload) {
    let params = prepareSearchParams(payload);
    params = {
      ...payload,
      ...params,
      username: payload.search,
    }
    return Client.post(resource, params, {
      headers: {
        action: "balance-report-total",
      },
    });
  },
};
