import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/fight/setting`;

export default {
    show(payload) {
        const params = {
            ...payload,
        };
        return Client.post(resource, params, {
            headers: {
                action: 'fight-default'
            }
        });
    },
    update(payload) {
        const params = {
            ...payload,
        };
        return Client.post(resource, params, {
            headers: {
                action: 'store-fight-default'
            }
        });
    },
};
