export default [
    {
        path: '/player',
        name: 'list-player',
        component: () => import('@/views/player/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.player',
            resource: 'player',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listPlayer',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/player/create',
        name: 'create-player',
        component: () => import('@/views/player/Create.vue'),
        meta: {
            pageTitle: 'breadcrumb.player',
            navActiveLink: 'list-player',
            resource: 'player',
            action: 'create',
            breadcrumb: [
                {
                    text: 'breadcrumb.listPlayer',
                    to: { name: 'list-player' }
                },
                {
                    text: 'breadcrumb.createPlayer',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/player/:id/update',
        name: 'update-player',
        component: () => import('@/views/player/Update.vue'),
        meta: {
            pageTitle: 'breadcrumb.player',
            navActiveLink: 'list-player',
            resource: 'player',
            action: 'update',
            breadcrumb: [
                {
                    text: 'breadcrumb.listPlayer',
                    to: { name: 'list-player' }
                },
                {
                    text: 'breadcrumb.updatePlayer',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/player/:id',
        name: 'view-player',
        component: () => import('@/views/player/View.vue'),
        meta: {
            pageTitle: 'breadcrumb.player',
            navActiveLink: 'list-player',
            resource: 'player',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listPlayer',
                    to: { name: 'list-player' }
                },
                {
                    text: 'breadcrumb.viewPlayer',
                    active: true,
                },
            ],
        },
    },
]
