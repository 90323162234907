import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';
import { prepareSearchParams } from '@/libs/helper';

const resource = `${Config.basePath}/v1/api/admin/role`;

export default {
  index(payload) {
    payload.searchFields = [
      ...payload.searchFields,
    ];
    payload = {
      ...payload,
    };
    const params = prepareSearchParams(payload);
    return Client.post(resource, {
      ...params
    }, {
      headers: {
        action: "role-list",
      },
    });
  },
  show(id, payload = {}) {
    const params = {
      id: id,
    };
    return Client.post(resource, params, {
      headers: {
        action: "role-show",
      },
    });
  },
  create(payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "role-store",
      },
    });
  },
  update(id, payload) {
    const params = {
      ...payload,
    };
    return Client.post(resource, params, {
      headers: {
        action: "role-update",
      },
    });
  },
  options(payload) {
    payload = {
      ...payload,
    };
    return Client.post(resource, payload, {
      headers: {
        action: "list-option",
      },
    });
  },
};
