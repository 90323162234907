export default [
    {
        path: '/bet-setting',
        name: 'bet-setting',
        component: () => import('@/views/betSetting/BetSetting.vue'),
        meta: {
            pageTitle: 'breadcrumb.betSetting',
            resource: 'bet-setting',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.betSetting',
                    active: true,
                },
            ],
        },
    },
]
