export default [
    {
        path: '/account-setting',
        name: 'account-setting',
        component: () => import('@/views/profile/AccountSetting.vue'),
        meta: {
            pageTitle: 'breadcrumb.accountSettings',
            resource: 'profile',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.accountSettings',
                    active: true,
                },
            ],
        },
    },
]
