export default [
    {
        path: '/result-report',
        name: 'result-report',
        component: () => import('@/views/resultReport/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.resultReport',
            resource: 'result-report',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listResultReport',
                    active: true,
                },
            ],
        },
    },
]
