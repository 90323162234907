export default [
    {
        path: '/role',
        name: 'list-role-permission',
        component: () => import('@/views/role/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.rolePermission',
            resource: 'role-permission',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listRolePermission',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/role/create',
        name: 'create-role-permission',
        component: () => import('@/views/role/Create.vue'),
        meta: {
            pageTitle: 'breadcrumb.rolePermission',
            navActiveLink: 'list-role-permission',
            resource: 'role-permission',
            action: 'create',
            breadcrumb: [
                {
                    text: 'breadcrumb.listRolePermission',
                    to: { name: 'list-role-permission' }
                },
                {
                    text: 'breadcrumb.createRolePermission',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/role/:id/update',
        name: 'update-role-permission',
        component: () => import('@/views/role/Update.vue'),
        meta: {
            pageTitle: 'breadcrumb.rolePermission',
            navActiveLink: 'list-role-permission',
            resource: 'role-permission',
            action: 'update',
            breadcrumb: [
                {
                    text: 'breadcrumb.listRolePermission',
                    to: { name: 'list-role-permission' }
                },
                {
                    text: 'breadcrumb.updateRolePermission',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/role/:id',
        name: 'view-role-permission',
        component: () => import('@/views/role/View.vue'),
        meta: {
            pageTitle: 'breadcrumb.rolePermission',
            navActiveLink: 'list-role-permission',
            resource: 'role-permission',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listRolePermission',
                    to: { name: 'list-role-permission' }
                },
                {
                    text: 'breadcrumb.viewRolePermission',
                    active: true,
                },
            ],
        },
    },
]
