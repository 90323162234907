export default [
    {
        path: '/company',
        name: 'list-company',
        component: () => import('@/views/company/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.company',
            resource: 'company',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listCompany',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/company/create',
        name: 'create-company',
        component: () => import('@/views/company/Create.vue'),
        meta: {
            pageTitle: 'breadcrumb.company',
            navActiveLink: 'list-company',
            resource: 'company',
            action: 'create',
            breadcrumb: [
                {
                    text: 'breadcrumb.listCompany',
                    to: { name: 'list-company' }
                },
                {
                    text: 'breadcrumb.createCompany',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/company/:id/update',
        name: 'update-company',
        component: () => import('@/views/company/Update.vue'),
        meta: {
            pageTitle: 'breadcrumb.company',
            navActiveLink: 'list-company',
            resource: 'company',
            action: 'update',
            breadcrumb: [
                {
                    text: 'breadcrumb.listCompany',
                    to: { name: 'list-company' }
                },
                {
                    text: 'breadcrumb.updateCompany',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/company/:id',
        name: 'view-company',
        component: () => import('@/views/company/View.vue'),
        meta: {
            pageTitle: 'breadcrumb.company',
            navActiveLink: 'list-company',
            resource: 'company',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listCompany',
                    to: { name: 'list-company' }
                },
                {
                    text: 'breadcrumb.viewCompany',
                    active: true,
                },
            ],
        },
    },
]
