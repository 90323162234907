export default {
    namespaced: true,
    state: {
        id: null,
        name: null,
        username: null,
        profilePicture: null,
        typeId: null,
        avalBalance: null,
        accountccy: null,
        perChannel: [],
    },
    getters: {},
    mutations: {
        UPDATE_PROFILE(state, val) {
            state.id = val.id;
            state.name = val.name;
            state.username = val.username;
            state.profilePicture = val.profilePicture;
            state.typeId = val.typeId;
            state.accountccy = val.account?.ccy;
            state.avalBalance = val.account?.avalBalance;
            state.perChannel = val.perChannel;
        },
    },
    actions: {},
}
