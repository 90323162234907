import Client from '@/repositories/Clients/AxiosClient';
import Config from '@/repositories/Clients/AxiosDefaultConfig';

const resource = `${Config.basePath}/v1/api/admin/fight`;

export default {
    setResult(payload) {
        const params = {
            ...payload,
        };
        return Client.post(resource, params, {
            headers: {
                action: 'draft-result'
            }
        });
    },
};
