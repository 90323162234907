export default [
    {
        path: '/agent',
        name: 'list-agent',
        component: () => import('@/views/agent/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.agent',
            resource: 'agent',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAgent',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/agent/create',
        name: 'create-agent',
        component: () => import('@/views/agent/Create.vue'),
        meta: {
            pageTitle: 'breadcrumb.agent',
            navActiveLink: 'list-agent',
            resource: 'agent',
            action: 'create',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAgent',
                    to: { name: 'list-agent' }
                },
                {
                    text: 'breadcrumb.createAgent',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/agent/:id/update',
        name: 'update-agent',
        component: () => import('@/views/agent/Update.vue'),
        meta: {
            pageTitle: 'breadcrumb.agent',
            navActiveLink: 'list-agent',
            resource: 'agent',
            action: 'update',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAgent',
                    to: { name: 'list-agent' }
                },
                {
                    text: 'breadcrumb.updateAgent',
                    active: true,
                },
            ],
        },
    },
    {
        path: '/agent/:id',
        name: 'view-agent',
        component: () => import('@/views/agent/View.vue'),
        meta: {
            pageTitle: 'breadcrumb.agent',
            navActiveLink: 'list-agent',
            resource: 'agent',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listAgent',
                    to: { name: 'list-agent' }
                },
                {
                    text: 'breadcrumb.viewAgent',
                    active: true,
                },
            ],
        },
    },
]
