export default [
    {
        path: '/transaction',
        name: 'list-transaction',
        component: () => import('@/views/transaction/List.vue'),
        meta: {
            pageTitle: 'breadcrumb.transaction',
            resource: 'transaction',
            action: 'read',
            breadcrumb: [
                {
                    text: 'breadcrumb.listTransaction',
                    active: true,
                },
            ],
        },
    },
]
